// Variables:
@import '../../styles/variables';

.input {
  border: none;
  outline: none;
  transition: all 0.25s;

  &-placeholder-small::placeholder {
    font-size: 0.8rem;
  }

  &-placeholder-large::placeholder {
    font-size: 1rem;
  }

  &-light {
    color: $medium;
    border-bottom: solid 2px $light;

    &::placeholder {
      color: $light;
    }

    &:focus {
      border-bottom: solid 2px $medium;
    }
  }

  &-dark {
    color: white;
    border-bottom: solid 2px $medium;

    &::placeholder {
      color: $light;
    }

    &:focus {
      border-bottom: solid 2px white;
    }
  }

  &-darkBlue {
    color: $medium;
    border-bottom: solid 1px $medium;

    &::placeholder {
      color: $light;
    }

    &:focus {
      border-bottom: solid 1px $lightBlue;
    }
  }
}
