@import '../../styles/variables';
$margin-item: 0rem;
$margin-item-small: 0.5rem;

// ===================================================================
.Navigation {
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  z-index: 800;

  background: white;

  display: flex;

  &-Content {
    height: 100%;
    width: 100%;

    margin: 0 80px;

    background: $menu;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  &-EdgeItem {
    width: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &-Item {
    position: relative;
    margin-bottom: $margin-item;
    font-size: 22px;
    letter-spacing: 1.5px;
    text-align: center;
    line-height: 36px;
    color: #ffffff;

    cursor: pointer;

    font-family: HelveticaNeueLT;

    &:hover {
      opacity: 0.75 !important;
    }

    &-small {
      position: relative;
      margin-bottom: $margin-item-small;

      font-size: 0.75rem;
      color: $light;

      cursor: pointer;
      text-transform: uppercase;

      &:hover {
        opacity: 0.75 !important;
      }
    }
  }

  &-Separation {
    background: rgba(255, 255, 255, 0.3);
    position: relative;
    width: 56px;
    margin: 1rem 0;
    height: 1px;

    &-NoLine {
      position: relative;
      width: 56px;
      margin: 1rem 0;
      height: 1px;
    }
  }
}
