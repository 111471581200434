// Variables:
@import '../../styles/variables';

.container {
  background-color: $white;
  height: 100%;
  overflow-y: scroll;

  h1 {
    font-weight: 200;
  }

  .content {
    color: $dark;
    max-width: 1200px;
    margin: 0 auto;
    padding: 3rem 0;
    font-weight: 300;
    letter-spacing: 0.035em;
  }
}
