@import 'styles/_variables.scss';

.DeviceDetails {
  // &-Small-Wrapper {
  //   overflow-y: auto;
  //   max-height: calc(100% - 64px);
  // }
  &-Small {
    flex: 1;
    flex-shrink: 0;
    margin-bottom: 0.5rem;

    &-Content {
      flex: 1;
      flex-shrink: 0;

      &-Code {
        font-size: 0.75rem;
        color: $greyText;
        margin-bottom: 0.5rem;
      }

      &-Image {
        img {
          height: 175px;
          width: 175px;
          max-height: 100%;
        }
      }
      &-Title {
        font-size: 1.2rem;
        font-weight: bold;
        margin-bottom: 0.5rem;
      }
      &-Description {
        color: $greyText;
        font-size: 0.9rem;
        margin-bottom: 1.5rem;
      }

      &-Dimensions {
        font-size: 0.65rem;
        font-weight: bold;
        margin-bottom: 0.5rem;
      }

      &-Details {
        font-size: 0.65rem;
        font-weight: bold;
        margin-bottom: 1.5rem;
      }

      &-AccordionWrapper {
        margin-top: 0.5rem;
        padding-top: 0.5rem;
        border-top: 1px solid $light;
        margin-bottom: 1rem;
      }

      &-Accordion {
        color: $greyText;

        .Accordion {
          font-weight: bold;
          text-transform: uppercase;
          font-size: 0.75rem;
        }
        .Panel {
          margin-top: 0.5rem;
          font-size: 0.75rem;
        }
      }
    }

    &-Bottom {
      display: flex;
      justify-content: flex-end;
    }
  }
}
