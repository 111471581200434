.AConfigSearchbar {
  position: absolute;
  left: 0;
  transition: left 0.2s linear;
  &-Active {
    position: absolute;
    left: -400px;
    transition: left 0.25s linear;
  }
}
