.Accordion.expanded > .Panel {
  max-height: 9000px;
  transition: max-height 0.3s ease-in;
  overflow-x: hidden;
}

.Panel {
  max-height: 0;
  overflow-y: hidden;
}
