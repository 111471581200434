.Searchbar {
  &-Active {
    position: absolute;
    left: 0;
    transition: 0.125s linear;
  }

  &-Inactive {
    position: absolute;
    left: -330px;
    transition: 0.2s linear;
  }
}
