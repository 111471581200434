// Variables:
@import '../../styles/_variables.scss';

.Scene {
  position: relative;

  width: 100%;
  height: 100%;

  background: transparent;
  canvas:focus {
    outline: none;
  }

  .Scene .download {
    position: absolute;
    bottom: 10px;
    right: 10px;
    z-index: 10;
  }
}

.Scene-Menu {
  position: relative;

  display: flex;
  flex-direction: column;
  flex: 1;

  background: transparent;
  color: $dark;

  overflow: hidden;

  &-Content {
    flex: 1;
    background: $extraLight;
    padding: 1.25rem;
    position: relative;

    overflow-x: hidden;
    overflow-y: auto;
  }

  &-Bottom {
    position: relative;

    display: flex;
    justify-content: center;
    background: $extraLight;
    padding: 1.25rem;
    margin-top: 0.5rem;
  }
}
