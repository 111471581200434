/* --------------------------------
Fonts
-------------------------------- */

//
// This CSS resource incorporates links to font software which is the valuable
// copyrighted property of Monotype and/or its suppliers. You may not attempt to copy,
// install, redistribute, convert, modify or reverse engineer this font software.
// Please contact Monotype with any questions regarding Web Fonts: http://www.fonts.com
//

@import url(https://fast.fonts.net/t/1.css?apiType=css&projectid=4518824c-dbd0-42e2-bbf5-a13cfffbf508);

//
// HelveticaNeueLT thin
//

@font-face {
  font-family: HelveticaNeueLT;
  font-display: swap;
  font-style: normal;
  font-weight: 200;
  src: url(../assets/fonts/7d63ccf8-e0ae-4dee-ad4d-bbc798aa5803.woff2) format('woff2'),
    url(../assets/fonts/b2c1327f-ab3d-4230-93d7-eee8596e1498.woff) format('woff');
}

//
// HelveticaNeueLT light
//

@font-face {
  font-family: HelveticaNeueLT;
  font-display: swap;
  font-style: normal;
  font-weight: 300;
  src: url(../assets/fonts/f9c5199e-a996-4c08-9042-1eb845bb7495.woff2) format('woff2'),
    url(../assets/fonts/2a34f1f8-d701-4949-b12d-133c1c2636eb.woff) format('woff');
}

//
// HelveticaNeueLT roman
//

@font-face {
  font-family: HelveticaNeueLT;
  font-display: swap;
  font-style: normal;
  font-weight: 400;
  src: url(../assets/fonts/08b57253-2e0d-4c12-9c57-107f6c67bc49.woff2) format('woff2'),
    url(../assets/fonts/08edde9d-c27b-4731-a27f-d6cd9b01cd06.woff) format('woff');
}

//
// HelveticaNeueLT medium
//

@font-face {
  font-family: HelveticaNeueLT;
  font-display: swap;
  font-style: normal;
  font-weight: 500;
  src: url(../assets/fonts/240c57a0-fdce-440d-9ce3-85e0cb56f470.woff2) format('woff2'),
    url(../assets/fonts/7802e576-2ffa-4f22-a409-534355fbea79.woff) format('woff');
}

//
// HelveticaNeueLT bold
//

@font-face {
  font-family: HelveticaNeueLT;
  font-display: swap;
  font-style: normal;
  font-weight: 700;
  src: url(../assets/fonts/1f4274cd-2674-481e-9023-69e33ffca161.woff2) format('woff2'),
    url(../assets/fonts/5e8d493c-be01-44b9-962e-504a5cf10dd8.woff) format('woff');
}
