.ADialog {
  opacity: 0;
  top: 0;
  left: 50%;
  transform: translate(-50%, 0);
  transition: 0.2s ease-in-out;

  &-Enter {
    opacity: 1;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: 0.3s ease-out;
  }
}
