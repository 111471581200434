// ===================================================================
@keyframes spinAround {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.LoadingSpinner {
  content: '';
  border-style: solid;
  border-radius: 50%;
  border-top-color: transparent !important;
  border-right-color: transparent !important;
  margin: 0 auto;

  animation: spinAround 0.5s infinite linear;
}
