@import 'src/styles/variables';
$actionWidth: 1.5rem;

.Menu-Tile {
  display: flex;
  background: white;
  padding: 0.5rem 0.75rem 0.5rem 0.5rem;
  margin-bottom: 0.75rem;
  cursor: pointer;
  &-Content {
    font-weight: bold;
    font-size: 0.85rem;
    margin-left: 0.5rem;
    align-items: center;
    flex: 1;
    display: flex;
    justify-content: space-between;

    &-Add {
      height: $actionWidth;
      width: $actionWidth;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      background: $lightBlue;
      border-color: transparent;
      padding: 3px;

      &-disabled {
        height: $actionWidth;
        width: $actionWidth;
        background: $lightBlueDisabled;
        cursor: not-allowed;
        border-color: transparent;
        padding: 3px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
}

.Menu-Tile-Disabled {
  display: flex;
  background: $extraLightDisabled;
  padding: 0.5rem 0.75rem 0.5rem 0.5rem;
  margin-bottom: 0.75rem;
  cursor: not-allowed;
  color: $grey;

  &-Content {
    font-weight: bold;
    font-size: 0.85rem;
    margin-left: 0.5rem;
    align-items: center;
    flex: 1;
    display: flex;
    justify-content: space-between;
  }
}
