.AItem {
  animation-name: animateIn;
  animation-duration: 100ms;
  animation-delay: calc(var(--animation-order) * 50ms);
  animation-timing-function: ease-out;
  animation-fill-mode: forwards;
  opacity: 0;

  @keyframes animateIn {
    0% {
      transform: translateX(-50px);
    }

    100% {
      transform: translateX(0);
      opacity: 1;
    }
  }

  &-White-Transparent {
    color: rgba(255, 255, 255, 0.5) !important;
  }
}
