.UsersTable {
  width: 100%;
  font-size: 0.85rem;
  thead,
  tbody {
    display: block;
  }
  th {
    border-bottom: 2px solid white;
    padding: 0.75rem 0;
  }
  thead {
    display: block;
    text-transform: uppercase;
    tr {
      display: flex;
      text-align: left;
    }
  }
  tbody {
    font-size: 1rem;

    max-height: 65vh;
    overflow-y: auto;
    width: 100%;
    tr {
      padding: 0.75rem 0;
      display: flex;
      width: 100%;
      transition: all ease-out 0.2s;
      border-bottom: 1px solid white;

      &:hover {
        background: rgba(255, 255, 255, 0.1);
      }
    }

    .first-col,
    .second-col,
    .third-col {
      &:hover {
        cursor: pointer;
      }
    }
  }

  .first-col {
    width: 25%;
  }
  .second-col {
    width: 55%;
  }

  .third-col,
  .fourth-col {
    width: 10%;
    text-align: center;
  }
}
