.ASlider {
  &-Left {
    left: 2%;
    transition: left 0.25s linear;
  }

  &-Right {
    left: 50%;
    transition: left 0.25s linear;
  }
}
