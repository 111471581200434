// Variables:
@import '../../../../../styles/variables';

.SaveDialog-Background {
  position: fixed;
  top: 0;
  left: 0;

  width: 100vw;

  z-index: 899;

  background: $dialogBackground;
}

.SaveDialog-Content {
  position: fixed;
  width: 755px;
  height: 580px;

  z-index: 900;

  background: $white;

  &-Icon {
    position: absolute;

    top: 1rem;
    right: 1rem;
  }

  &-Main {
    margin: 3rem auto 0;
    padding: 1rem 0 0;
    max-width: 400px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}
