// Variables:
@import '../../styles/variables';

.ShareDialog-Background {
  position: fixed;
  top: 0;
  left: 0;

  width: 100vw;

  z-index: 899;

  background: $dialogBackground;
}

.ShareDialog-Content {
  position: fixed;
  /* top: 50%;
  left: 50%;

  transform: translate(-50%, -50%); */

  width: 755px;
  height: 580px;

  z-index: 900;

  background: $white;

  &-Icon {
    position: absolute;

    top: 1rem;
    right: 1rem;
  }

  &-Main {
    margin: 0 auto;
    padding: 1rem 0 0;

    display: flex;
    flex-direction: column;
    align-items: center;

    &-Sharing {
      width: 60%;

      margin: 2rem 0;

      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
    }

    &-Send {
      width: 60%;

      margin: 1rem 0 1rem;

      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
    }

    &-SwitchCollection {
      width: 60%;

      margin-top: 2rem;

      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;

      &-Item {
        width: 50%;

        padding-bottom: 1rem;

        display: flex;
        flex-direction: row;
        align-items: center;
      }
    }
  }
}
