// Variables:
@import 'src/styles/variables';

.DefaultLayout {
  color: #fff;
  width: 100%;
  max-width: 100vw;
  margin: 0;

  &-Content {
    margin-top: $header-height;
    height: calc(var(--vh, 1vh) * 100 - 80px);
  }
}
