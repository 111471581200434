.ASimpleWithHeight {
  opacity: 0;
  max-height: 0;
  height: auto;
  transition: all 0.2s;

  &-Active {
    opacity: 1;
    max-height: 100%;
    height: auto;
    transition: all 0.3s;
  }
}
