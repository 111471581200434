// Variables:
@import '../../styles/variables';

.LoadingError-Background {
  position: fixed;
  top: 0;
  left: 0;

  width: 100vw;

  z-index: 801;

  background: $dialogBackground;
}

.LoadingError-Content {
  position: fixed;

  width: 460px;
  height: 370px;

  @media screen and (max-width: 450px) {
    width: 100%;
  }

  z-index: 802;

  background: $white;

  &-Icon {
    position: absolute;

    top: 1rem;
    right: 1rem;
  }

  &-Main {
    margin: 0 auto;
    padding: 2rem;
    height: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
