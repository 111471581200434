// Variables:
@import '../../styles/variables';

.DelteConfirm-Background {
  position: fixed;
  top: 0;
  left: 0;

  width: 100vw;

  z-index: 1001;

  background: $dialogBackground;
}

.DelteConfirm-Content {
  position: fixed;
  /*  top: 50%;
  left: 50%;

  transform: translate(-50%, -50%); */

  width: 460px;
  height: 370px;

  z-index: 1002;

  background: $white;

  &-Icon {
    position: absolute;

    top: 1rem;
    right: 1rem;
  }

  &-Main {
    margin: 0 auto;
    padding: 2rem;
    height: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
